<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">
                    Sorggrupper<span v-if="ready && !loading">&nbsp;({{ grupper.length }})</span>
                </h1>
                <v-btn color="primary" to="/sorg/gruppe/ny">
                    Ny gruppe
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-toolbar class="mt-6" color="grey lighten-4" elevation="4">
                <div class="mr-7">
                    <griefs-actions v-model="sorterteGrupper" :headers="headers" :alle="true"></griefs-actions>
                </div>
                <v-col cols="3">
                    <v-select v-model="aggreger" :items="aggregerValg" item-text="navn" item-value="id" label="Vis" hide-details></v-select>
                </v-col>
                <v-col cols="3" v-if="aggreger == false">
                    <v-select v-model="bareAktive" :items="bareAktiveValg" item-text="navn" item-value="id" label="Status" hide-details></v-select>
                </v-col>
            </v-toolbar>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="grupper"
                    :custom-sort="customSort"
                    :items-per-page="-1"
                    :loading="loading"
                    :item-class="classRow"
                    v-on:click:row="openGroup"
                    sort-by="tittel"
                    must-sort
                    hide-default-footer
                >
                    <template v-slot:item.ledere="{ item }"> {{ item | ledere }} </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GriefsActions from '@/pages/grief/Actions.vue';

export default {
    name: 'GriefGroups',
    components: {
        GriefsActions,
    },
    data() {
        return {
            ready: false,
            loading: false,

            headers: [],
            allHeaders: [
                { text: 'Gruppe', value: 'tittel' },
                { text: 'Gruppeledere', value: 'ledere', sortable: false },
                { text: 'Møtetidspunkt', value: 'motetidspunkt' },
                { text: 'Antall grupper', value: 'antallGrupper', align: 'right' },
                { text: 'Plasser', value: 'antallPlasser', align: 'right' },
                { text: 'Medlemmer', value: 'antallDeltakere', align: 'right' },
                { text: 'Tilordnet', value: 'antallTilordnet', align: 'right' },
                { text: 'Ledige plasser', value: 'antallLedigePlasser', align: 'right' },
                { text: 'På venteliste', value: 'antallVenteliste', align: 'right' },
            ],
            grupper: [],
            sorterteGrupper: [],

            aggreger: false,
            aggregerValg: [
                { id: false, navn: 'Enkeltgrupper' },
                { id: true, navn: 'Gruppert etter type' },
            ],

            bareAktive: true,
            bareAktiveValg: [
                { id: true, navn: 'Aktive' },
                { id: false, navn: 'Alle' },
            ],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),
    },
    watch: {
        aggreger: function () {
            this.filterHeaders();
            this.update();
        },
        bareAktive: function () {
            this.update();
        },
    },
    filters: {
        ledere: function (gruppe) {
            let ledere = [];
            if (gruppe.ledere && Array.isArray(gruppe.ledere)) {
                for (const leder of gruppe.ledere) {
                    if (!leder.fjernet && leder.person) {
                        ledere.push([leder.person.fornavn, leder.person.etternavn].join(' '));
                    }
                }
            }
            return ledere.join(', ');
        },
    },
    created: async function () {
        this.filterHeaders();
        await this.update();
    },
    methods: {
        ...mapActions('api', ['request']),

        /**
         * filterHeaders
         */
        filterHeaders: function () {
            this.headers = this.allHeaders.filter((header) => {
                switch (header.value) {
                    case 'ledere':
                    case 'motetidspunkt':
                        return this.aggreger == false;

                    case 'antallGrupper':
                    case 'antallVenteliste':
                        return this.aggreger != false;
                }
                return true;
            });
        },

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;
                this.metoder = [];

                let grupper = [];

                let personer = [];
                let response = await this.request({
                    method: 'get',
                    url: '/person/bruker/sorg',
                });
                if (response && Array.isArray(response)) {
                    personer = response;
                }

                response = await this.request({
                    method: 'get',
                    url: '/sorggruppe',
                    params: {
                        bareAktive: this.bareAktive,
                    },
                });
                if (response && Array.isArray(response)) {
                    for (const gruppe of response) {
                        if (this.hasRole('ANSATT')) {
                            grupper.push(gruppe);
                        } else if (gruppe.ledere) {
                            for (const leder of gruppe.ledere) {
                                if (!leder.fjernet && leder.person.id == this.user.id) {
                                    grupper.push(gruppe);
                                    break;
                                }
                            }
                        }
                    }

                    for (const gruppe of grupper) {
                        let plasser = gruppe.antallPlasser,
                            deltakere = 0,
                            tilordnet = 0,
                            ledige = 0;
                        if (gruppe && gruppe.deltakere) {
                            for (const deltaker of gruppe.deltakere) {
                                if (!deltaker.fjernet) {
                                    deltakere++;
                                }
                            }
                        }
                        for (const person of personer) {
                            if (
                                person.sorginformasjon &&
                                person.sorginformasjon.tilordnetSorgGruppeId &&
                                person.sorginformasjon.tilordnetSorgGruppeId == gruppe.id
                            ) {
                                if (!Array.isArray(person.sorgGrupper) || !person.sorgGrupper.find((g) => g.id == gruppe.id)) tilordnet++;
                            }
                        }
                        if (plasser > 0) {
                            ledige = plasser - deltakere;
                        }

                        gruppe.antallDeltakere = deltakere || '';
                        gruppe.antallTilordnet = tilordnet || '';
                        gruppe.antallLedigePlasser = ledige || '';
                    }
                }

                if (this.aggreger) {
                    response = await this.request({
                        method: 'get',
                        url: '/sorggruppe/type',
                        params: { inkluderInaktive: false },
                    });
                    const typer = [];

                    if (response && Array.isArray(response)) {
                        for (const type of response) {
                            let antallGrupper = 0,
                                antallPlasser = 0,
                                antallDeltakere = 0,
                                anallTilordnet = 0,
                                antallLedige = 0,
                                antallVenteliste = 0;

                            for (const gruppe of grupper) {
                                if (gruppe.type.id == type.id) {
                                    antallGrupper++;
                                    antallPlasser += gruppe.antallPlasser || 0;
                                    antallDeltakere += gruppe.antallDeltakere || 0;
                                    anallTilordnet += gruppe.antallTilordnet || 0;
                                    antallLedige += gruppe.antallLedigePlasser || 0;
                                }
                            }

                            for (const person of personer) {
                                if (person.sorginformasjon && person.sorginformasjon.sorgType && person.sorginformasjon.sorgType.id == type.id) {
                                    antallVenteliste++;
                                }
                            }

                            type.tittel = type.navn;

                            type.antallGrupper = antallGrupper || '';
                            type.antallPlasser = antallPlasser || '';
                            type.antallDeltakere = antallDeltakere || '';
                            type.antallTilordnet = anallTilordnet || '';
                            type.antallLedigePlasser = antallLedige || '';
                            type.antallVenteliste = antallVenteliste || '';

                            typer.push(type);
                        }
                        grupper = typer;
                    }
                }

                this.grupper = grupper;
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                a = (a[index] || '').toString();
                b = (b[index] || '').toString();

                if (!isDesc[0]) {
                    return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                } else {
                    return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                }
            });
            this.sorterteGrupper = items;
            return items;
        },

        /**
         * openGroup
         */
        openGroup: function (gruppe) {
            if (!this.aggreger) {
                this.$router.push({ name: '/sorg/gruppe', params: { id: gruppe.id } });
            }
        },

        classRow() {
            return this.aggreger ? '' : 'v-data-table-item--link';
        },
    },
};
</script>
<style lang="scss">
.v-data-table-item--link {
    cursor: pointer;
}
</style>
